import React from 'react'
import componentStyles from './LinkTile.module.scss'
import { FaFileDownload } from 'react-icons/fa'
import { FormattedMessage } from 'gatsby-plugin-intl'

const LinkTile = ({ path, ...props }) => {
  return (
    <a href={path} className={componentStyles.tile} download>
      <div className={componentStyles.image}>
        <div className={componentStyles.content}>{props.children}</div>
      </div>
      <div className={componentStyles.download}>
        <FaFileDownload className={componentStyles.icon} />
        <span>
          <FormattedMessage id='commonHdrs.download' />
        </span>
      </div>
    </a>
  )
}

export default LinkTile
